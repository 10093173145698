import { useEffect, useMemo, useState } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { postIndustry } from '../data'
import { Button, Container, FormControl, InputLabel, LinearProgress, NativeSelect } from '@material-ui/core'
import { Helmet } from 'react-helmet-async'

function ReactionsProcess() {
    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [config, setConfig] = useState({
        rsystem: '1B-VKF',
        rfacility: '1DQ1-A - GEZ T2 Reprocessing T2 Reactions',
    })
    const fetchData = () => {
        Promise.all([postIndustry(['process_reactions'], { config: config })])
            .then((results) => {
                setData(results[0].data)
                setLoaded(true)
                setError(null)
            })
            .catch((error) => {
                setError(error)
            })
    }

    const columns = useMemo(
        () => [
            {
                field: 'id',
                headerName: '',
                width: 50,
                renderCell: (params) =>
                    params.row.invalid ? null : (
                        <img
                            src={`https://images.evetech.net/types/${params.value}/icon?size=32`}
                            alt={params.row.itemName}
                            width={25}
                            height={25}
                        />
                    ),
            },
            {
                field: 'typeName',
                headerName: 'Item',
                width: 400,
            },
            {
                field: 'groupName',
                headerName: 'Group',
                width: 200,
            },
            {
                field: 'real_cost',
                headerName: 'Cost',
                width: 200,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'sell',
                headerName: '1DQ sell',
                width: 200,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'local_volume',
                headerName: 'Volume',
                width: 200,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'sell_export',
                headerName: 'Jita sell',
                width: 200,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'export_volume',
                headerName: 'Volume',
                width: 200,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'volume_price',
                headerName: 'ISK per m^3',
                width: 200,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'run_price',
                headerName: 'ISK per run',
                width: 200,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
        ],
        []
    )

    useEffect(() => {
        fetchData()
    }, [])

    if (error) {
        return (
            <Container>
                <h3>Error</h3>
                <p>{error.message}</p>
            </Container>
        )
    }

    if (!loaded) return <LinearProgress />

    return (
        <div style={{ width: '100%' }}>
            <Helmet>
                <title>Reactions</title>
            </Helmet>
            <div style={{ textAlign: 'center' }}>
                Cost: build price from jita buy, except blocks in 1DQ <br />
                Jita sell: value after removing shipping cost (I'm not assuming your broker fee rate) <br />
            </div>
            <FormControl>
                <InputLabel>Reacting Facility</InputLabel>
                <NativeSelect
                    inputProps={{
                        name: 'rfacility',
                    }}
                    onChange={(e: HashChangeEvent<HTMLSelectElement>) =>
                        setConfig((x) => ({ ...x, rfacility: e.target.value }))
                    }
                >
                    <option value={'1DQ1-A - GEZ T2 Reprocessing T2 Reactions'}>T2 Reactor</option>
                    <option value={'GEZ T1 Tatara'}>T1 Reactor</option>
                </NativeSelect>
            </FormControl>
            <FormControl>
                <InputLabel>Reacting System</InputLabel>
                <NativeSelect
                    inputProps={{
                        name: 'rsystem',
                    }}
                    onChange={(e: HashChangeEvent<HTMLSelectElement>) =>
                        setConfig((x) => ({ ...x, rsystem: e.target.value }))
                    }
                >
                    <option value={'1B-VKF'}>1B-VKF</option>
                    <option value={'1DQ1-A'}>1DQ1-A</option>
                    <option value={'4O-239'}>4O-239</option>
                    <option value={'4X0-8B'}>4X0-8B</option>
                    <option value={'5BTK-M'}>5BTK-M</option>
                    <option value={'7G-QIG'}>7G-QIG</option>
                    <option value={'7UTB-F'}>7UTB-F</option>
                    <option value={'8RQJ-2'}>8RQJ-2</option>
                    <option value={'8WA-26'}>8WA-Z6</option>
                    <option value={'39P-1J'}>39P-1J</option>
                    <option value={'D-3GIQ'}>D-3GIQ</option>
                    <option value={'F-9PXR'}>F-9PXR</option>
                    <option value={'K-6K16'}>K-6K16</option>
                    <option value={'HZAQ-W'}>HZAQ-W</option>
                    <option value={'IP6V-X'}>IP6V-X</option>
                    <option value={'Jita'}>Jita</option>
                    <option value={'L-FVHR'}>L-FVHR</option>
                    <option value={'LUA5-L'}>LUA5-L</option>
                    <option value={'MO-GZ5'}>MO-GZ5</option>
                    <option value={'N-8YET'}>N-8YET</option>
                    <option value={'NIDJ-K'}>NIDJ-K</option>
                    <option value={'PS-94K'}>PS-94K</option>
                    <option value={'PUIG-F'}>PUIG-F</option>
                    <option value={'Q-JQSG'}>Q-JQSG</option>
                    <option value={'QC-YX6'}>QC-YX6</option>
                    <option value={'R5-MM8'}>R5-MM8</option>
                    <option value={'T-IPZB'}>T-IPZB</option>
                    <option value={'T-M0FA'}>T-M0FA</option>
                    <option value={'W-KQPI'}>W-KQPI</option>
                    <option value={'Y-OMTZ'}>Y-OMTZ</option>
                    <option value={'Y5C-YD'}>Y5C-YD</option>
                </NativeSelect>
            </FormControl>
            <FormControl>
                <Button variant="contained" onClick={fetchData}>
                    Apply
                </Button>
            </FormControl>
            <ul style={{ textAlign: 'left' }}>
                <li>Reaction facility: {config.rfacility}</li>
                <li>Reaction system: {config.rsystem}</li>
            </ul>
            <DataGrid columns={columns} rows={data} rowHeight={25} autoHeight />
        </div>
    )
}

export default ReactionsProcess
